import React, { useContext, useEffect, useRef } from 'react';

import { Eventcalendar, toast, setOptions, Popup, Input, Textarea, Datepicker, localeNo, snackbar, Checkbox, Switch, CalendarNav, CalendarPrev, CalendarNext, CalendarToday, formatDate } from '@mobiscroll/react';

import { onAuthStateChanged, auth, collection, database, getFunctions, httpsCallable } from '../Utils/firebaseConfig'
import { v4 as uuidv4 } from 'uuid';

import { fetchEvents, addEvents, updateEvent, deleteEvent, restoreEvent, fetcSettings } from '../Utils/firebaseCRUD';
import { resources } from "../Utils/resources"

import '../styles.scss'
import "@mobiscroll/react/dist/css/mobiscroll.react.min.css";
import './CalendarComponent.css'

import { onSnapshot, serverTimestamp, Timestamp } from 'firebase/firestore';

import Cursor from "./Cursor"
import { Avatar } from './Avatar';
import CursorIndicator from './CursorIndicator';
// import { useOthers, useUpdateMyPresence, useSelf } from "../Utils/liveblocksConfig";
import { RoomProvider, useOthers, useSelf, useUpdateMyPresence } from "@liveblocks/react";

import holidays from 'holidays-norway';
import { uuidToBase32Hex, base32HexToUUID } from '../Utils/uuidToBase32';
// HOOKS
import { useAuth } from "../Hooks/useAuth";


import { CircularProgress, Box, Button } from '@mui/material';

import CustomEventPopup from './CustomEventPopup';

setOptions({
    //MOTIONAIR
    locale: localeNo,
    theme: 'ios',
    themeVariant: 'light'
});

const responsivePopup = {
    medium: {
        display: 'anchored',
        width: 400,
        fullScreen: false,
        touchUi: false
    },
    large: {
        display: 'center',
        maxHeight: '80vh',
        maxWidth: 800,
        fullScreen: true,
        touchUi: true
    }
};
const colorPopup = {
    medium: {
        display: 'anchored',
        touchUi: false,
        buttons: []
    }
}

const pastel = () => {
    var hue = Math.floor(Math.random() * 360);
    var pastel = 'hsl(' + hue + ', 100%, 80%)';
    return pastel
}
//EN MULIGHET FOR Å FÅ FLERE FARGER::
// const colors = [pastel(), pastel(), pastel(), pastel(), pastel(), pastel(), pastel(), pastel(), pastel(), pastel()];


const colors = [
    // Yellows & Oranges
    '#FFEB3C', // original bright yellow
    '#FFD700', // golden yellow
    '#FF9900', // original orange
    '#FF8C00', // dark orange

    // Pinks & Purples
    '#FF99FF', // original pink
    '#FF66FF', // brighter pink
    '#9C26B0', // original purple
    '#8E24AA', // similar purple
    '#3F51B5', // original indigo
    '#3949AB', // similar indigo

    // Blues & Cyans
    '#03A9F4', // bright blue

    // Greens & Teals
    '#009788', // original teal
    '#66FFCC', // original mint
    '#4CAF50', // original green
    '#4BAF4F', // similar green

    // Browns
    '#7E5D4E', // original brown
    '#795548'  // similar brown
];


const Calendar = () => {
    // CUSTOM CODE:
    // DATABASE CONSTANT, CHANGE FOR TESTING PURPOSES:
    const eventDatabase = process.env.REACT_APP_SERVER_LOCATION

    const { user, userSettings } = useAuth();
    const userColor = userSettings["cursor-color"]
    // console.log(userSettings)
    const [isChecked, setIsChecked] = React.useState([]);
    const [isEditable, setIsEditable] = React.useState(false);
    const [isBusy, setIsBusy] = React.useState(false);
    const busyColor = '#ff0000';
    const [isX, setIsX] = React.useState(false);
    const [mobileShow, setMobileShow] = React.useState(true);
    const [zoomLevel, setZoomLevel] = React.useState(1440);
    const [isHoverOpen, setIsHoverOpen] = React.useState(false);
    const timerRef = React.useRef(null);
    const [eventContent, setEventContent] = React.useState([]);
    const [calendarColors, setCalendarColors] = React.useState();
    const timelineRef = React.useRef(null);
    // const [isLoggingIn, setIsLoggingIn] = React.useState(true);
    const [norwegianHolidays, setNorwegianHolidays] = React.useState([]);
    const [isHoverDayOpen, setIsHoverDayOpen] = React.useState(false);
    const [holidayName, setHolidayName] = React.useState(null);
    const [holidayAnchor, setHolidayAnchor] = React.useState(null);
    const [currentDate, setCurrentDate] = React.useState(new Date());
    const [isLoading, setIsLoading] = React.useState(true);
    const [eventsLoaded, setEventsLoaded] = React.useState(false);
    const { loading: authLoading } = useAuth(); // Get auth loading state

    useEffect(() => {
        // Check every minute if we've crossed midnight
        const interval = setInterval(() => {
            const now = new Date();
            if (now.getDate() !== currentDate.getDate()) {
                setCurrentDate(now);
            }
        }, 60000); // check every minute

        return () => clearInterval(interval);
    }, [currentDate]);


    // LiveBlocks
    const others = useOthers();
    const currentUser = useSelf();
    const updateMyPresence = useUpdateMyPresence();
    const [scrollPos, setScrollPos] = React.useState({ x: 0, y: 0, width: 0 })



    // STANDARD CODE:
    const [myEvents, setMyEvents] = React.useState();
    const [tempEvent, setTempEvent] = React.useState(null);
    const originalResourcesRef = useRef(null); // Track original resources
    const [isOpen, setOpen] = React.useState(false);
    const [isEdit, setEdit] = React.useState(false);
    const [anchor, setAnchor] = React.useState(null);
    const [start, startRef] = React.useState(null);
    const [end, endRef] = React.useState(null);
    const [popupEventTitle, setTitle] = React.useState('');
    const [popupEventDescription, setDescription] = React.useState('');
    const [popupEventAllDay, setAllDay] = React.useState(true);
    const [popupEventDate, setDate] = React.useState([]);
    const [popupEventTime, setTime] = React.useState([]);
    const [popupEventStatus, setStatus] = React.useState('busy');
    const [popupEventEdited, setEdited] = React.useState(null);
    const [popupEventCreated, setCreated] = React.useState(null);
    const [mySelectedDate, setSelectedDate] = React.useState(new Date());
    const [colorPickerOpen, setColorPickerOpen] = React.useState(false);
    const [colorAnchor, setColorAnchor] = React.useState(null);
    const [selectedColor, setSelectedColor] = React.useState('');
    const [tempColor, setTempColor] = React.useState('');
    const colorPicker = React.useRef();

    const colorButtons = React.useMemo(() => [
        'cancel',
        {
            text: 'Set',
            keyCode: 'enter',
            handler: () => {
                setSelectedColor(tempColor);
                setColorPickerOpen(false);
            },
            cssClass: 'mbsc-popup-button-primary'
        }
    ], [tempColor]);

    let viewSettings = {
        timeline: {
            type: 'year',
            weekNumbers: true,
            rowHeight: 'variable',
            timeCellStep: zoomLevel
        }
    };

    function findNestedObj(entireObj, keyToFind, valToFind) {
        let foundObj;
        JSON.stringify(entireObj, (_, nestedValue) => {
            if (nestedValue && nestedValue[keyToFind] === valToFind) {
                foundObj = nestedValue;
            }
            return nestedValue;
        });
        return foundObj;
    };


    //---------------------------- OBS PÅ QUERYSELECTOR HER. UPDATES KAN ENDRE DEN ---------------------//

    // LIVEBLOCKS MULTI CURSOR
    useEffect(() => {
        const instance = timelineRef.current;

        if (instance) {
            // Wait for next render cycle to ensure elements are mounted
            setTimeout(() => {
                const container = document.querySelector('.mbsc-timeline-grid-scroll')
                const containerWidth = document.querySelector('.mbsc-timeline-header-bg.mbsc-flex-1-0.mbsc-flex.mbsc-ios')
                const handleScroll = () => {
                    // console.log("scollign")
                    if (container && containerWidth) {
                        // console.log("Scrolling inside")
                        const containerWidthBox = containerWidth.getBoundingClientRect();
                        setScrollPos({
                            x: container.scrollLeft,
                            y: container.scrollTop,
                            width: containerWidthBox.width
                        });
                    }
                };

                if (container) {
                    container.addEventListener('scroll', handleScroll);
                }

                return () => {
                    container?.removeEventListener('scroll', handleScroll);
                };
            }, 0);
        }
    }, [timelineRef.current]);



    const mouseMove = (e) => {
        const rect = document.querySelector('.mbsc-timeline-header-bg.mbsc-flex-1-0.mbsc-flex.mbsc-ios').getBoundingClientRect();
        let x = 0; // x position within the scrollable div
        let y = 0;  // y position within the scrollable div

        x = e.clientX - rect.left + 194; // x position within the scrollable div
        y = e.clientY - rect.top + 98;  // y position within the scrollable div
        updateMyPresence({
            name: user.email,
            displayName: user.displayName,
            cursor: { x, y },
            scrollPosition: scrollPos,
            iconImage: userSettings.showProfilePictureInAvatar ? userSettings.userImage : "",
            cursorImage: userSettings.showProfilePictureInCursor ? userSettings.showProfilePictureInCursor : "",
            color: userColor ? userColor : { color: "#808080", dim: "#414141" },
            lastActive: new Date()
        });
    }




    const getArr = React.useCallback((arr, flat = []) => {
        for (let i = 0; i < arr.length; i++) {
            if (arr[i].children && arr[i].children.length) {
                flat.push(arr[i]);
                console.log(arr[i])
                getArr(arr[i].children, flat);
            }
        }
        return flat;
    })


    useEffect(() => {
        //THIS INITIATES REALTIME LISTENERS
        const userRef = collection(database, eventDatabase);
        setIsLoading(true); // Start loading
        const unsubscribe = onSnapshot(userRef, snapshot => {
            setMyEvents(snapshot.docs.map(doc => ({
                id: doc.data().id,
                title: doc.data().title,
                description: doc.data().description,
                start: doc.data().start.toDate(),
                end: doc.data().end.toDate(),
                allDay: doc.data().allDay,
                status: doc.data().status,
                color: doc.data().color,
                resource: doc.data().resource,
                editable: doc.data().editable,
                busy: doc.data().busy,
                createdAt: doc.data().createdAt,
                updatedAt: doc.data().updatedAt,
                visibleInCalendar: doc.data().visibleInCalendar,
                rentmanEvent: doc.data().rentmanEvent,
                subprojectStatus: doc.data().subprojectStatus,
            })));
            setEventsLoaded(true); // Mark events as loaded
        });

        return () => {
            unsubscribe();
            console.log('unsubscribed!');
        }
    }, [eventDatabase]);

    // New effect to handle overall loading state
    useEffect(() => {
        if (eventsLoaded && myEvents && !authLoading) {
            // Add a small delay to allow for calendar initialization
            const timer = setTimeout(() => {
                setIsLoading(false);
            }, 1000);
            return () => clearTimeout(timer);
        }
    }, [eventsLoaded, myEvents, authLoading]);







    //TODO: ADD FETCH FUNCTION

    const asyncSyncWithRentman = async () => {
        const functions = getFunctions();
        const syncWithRentman = httpsCallable(functions, 'syncWithRentman')
        try {
            const test = await syncWithRentman()
            return test
        } catch (error) {
            console.log(error)
        }
    }

    useEffect(() => {
        try {
            asyncSyncWithRentman()
        } catch (error) {
            console.log(error)
        }
    }, [])

    //USED FOT ALTERNATIVE BACKGROUND HEX RENDERING
    function LightenDarkenColor(col, amt) {
        col = parseInt(col, 16);
        let color = (((col & 0x0000FF) + amt) | ((((col >> 8) & 0x00FF) + amt) << 8) | (((col >> 16) + amt) << 16)).toString(16);
        return "#" + color;
    }

    //USE EFFECT FOR RESOURCE COLOR UPDATES
    useEffect(() => {
        const freelanceResourceAvailable = () => {
            // Dynamically create resource arrays
            const crewResource = resources.find(resource => resource.name === "Crew");

            const freelanceResources = crewResource?.children?.find(child => child.name === "Freelancers")?.children?.map(child => child.id) || [];

            const equipmentResources = resources
                .find(resource => resource.name === "Equipment")?.children?.map(child => child.id) || [];

            const allResources = resources
                .flatMap(resource => resource.children || [])
                .map(child => child.id);

            // Initialize colors with predefined values
            const colors = [
                {
                    background: '#FFF3F4',
                    resource: Array.from(Array(101).keys()), // Background for Sundays
                    recurring: {
                        repeat: 'weekly',
                        weekDays: 'SU',
                    },
                },
                {
                    background: '#f7f2f2',
                    resource: Array.from(Array(101).keys()), // Background for Saturdays
                    recurring: {
                        repeat: 'weekly',
                        weekDays: 'SA',
                    },
                },
                {
                    date: currentDate, // Green background for "today" across all rows
                    background: 'rgb(227, 255, 227)',
                    resource: allResources,
                },
            ];

            const addColor = (startDate, endDate, resource, background, isEditable) => {
                const color = { start: startDate, end: endDate, resource, background };
                colors.push(color);

                if (!isEditable) {
                    const nonEditableColor = { ...color, background: '#808080' }; // Non-editable color
                    colors.push(nonEditableColor);
                }
            };

            if (myEvents && Array.isArray(myEvents)) {
                myEvents.forEach((event) => {
                    // Initialize dates based on allDay flag
                    let startDate, endDate;
                    if (event.allDay) {
                        startDate = event.start ? new Date(event.start) : null;
                        endDate = event.end ? new Date(event.end) : null;
                        if (startDate) startDate.setHours(0, 0, 1);
                        if (endDate) endDate.setHours(23, 59, 59);
                    } else {
                        startDate = event.start ? new Date(event.start) : null;
                        endDate = event.end ? new Date(event.end) : null;
                    }

                    // Check if startDate and endDate are valid
                    if (!startDate || isNaN(startDate.getTime()) || !endDate || isNaN(endDate.getTime())) {
                        console.warn('Invalid event dates:', event);
                        return; // Skip this event if dates are invalid
                    }

                    // Check for freelance resources
                    if (event.resource.some((r) => freelanceResources.includes(r))) {
                        const freelanceColorBackground = `${event.color}${Math.floor(0.4 * 255).toString(16).padStart(2, '0')}`;
                        addColor(startDate, endDate, [1001], freelanceColorBackground, event.editable);
                    }

                    // Check for equipment resources
                    if (event.resource.some((r) => equipmentResources.includes(r))) {
                        const equipmentColorBackground = `${event.color}${Math.floor(0.4 * 255).toString(16).padStart(2, '0')}`;
                        addColor(startDate, endDate, [101], equipmentColorBackground, event.editable);
                    }
                });

                // Set the new colors in the state
                setCalendarColors(colors);
            }
        };

        freelanceResourceAvailable();
    }, [currentDate, myEvents]);



    const handleResourceCheckInEvent = (resource) => {
        setIsChecked((prevChecked) => {
            if (prevChecked.includes(resource.id)) {
                // Remove the resource ID if it is already checked
                return prevChecked.filter((id) => id !== resource.id);
            } else {
                // Add the resource ID if it is not checked
                return [...prevChecked, resource.id];
            }
        });
    };

    const countDaysTouched = (startDateStr, endDateStr) => {
        const startDate = new Date(startDateStr);
        const endDate = new Date(endDateStr);

        // Normalize both dates to midnight
        startDate.setHours(0, 0, 0, 0);
        endDate.setHours(0, 0, 0, 0);

        // Calculate the difference in time
        const differenceInTime = endDate.getTime() - startDate.getTime();

        // Calculate the difference in days
        const differenceInDays = Math.ceil(differenceInTime / (1000 * 3600 * 24));

        // If the difference is negative, it means the end date is before the start date
        return differenceInDays < 0 ? 0 : differenceInDays + 1; // Add 1 to include the start day
    };

    const myScheduleEvent = React.useCallback((data) => {
        const ev = data.original;
        const color = data.color;

        let TotalDays = countDaysTouched(data.startDate, data.endDate);

        if (ev.title === "Juleferie") {
            const lights = Math.round(TotalDays * 1.2);

            return (
                <div className={`christmas md-timeline-template-event md-timeline-template-event-christemas ${mobileShow ? "" : "mobile-toggle"}`}>
                    <div className="mbsc-schedule-event-inner md-timeline-template-event-cont-expand">
                        <div id="flex-event-expand">
                            <span className="md-timeline-template-title-christemas">PRIVAT: {ev.title} - {TotalDays} days</span>
                            <span className="md-timeline-template-desc-christemas" >{ev.description}</span>
                        </div>
                    </div>
                    <ul class="strand">
                        {Array.from(Array(lights), (e, i) => {
                            return <li key={i}></li>
                        })}
                    </ul>
                </div>
            )

        }
        else if (data.original.busy === true) {
            return <div className={`md-timeline-template-event md-timeline-template-event-busy ${mobileShow ? "" : "mobile-toggle"}`}>
                <div className="mbsc-schedule-event-inner md-timeline-template-event-cont-expand">
                    <div id="flex-event-expand">
                        <span className="md-timeline-template-title-busy">PRIVAT: {ev.title} - {TotalDays} days</span>
                        <span className="md-timeline-template-desc-busy" >{ev.description}</span>
                    </div>
                    <span className="md-timeline-template-time-busy">{
                        data.startDate.toLocaleString('default', { month: 'short', day: 'numeric' }) +
                        " " +
                        data.start +
                        " - " +
                        data.endDate.toLocaleString('default', { month: 'short', day: 'numeric' }) +
                        " " +
                        data.end
                    }
                    </span>
                </div>
            </div>

        }

        // else if (data.resource.length === 1 && data.resource.includes(4)) {

        //     return <div className={`md-timeline-template-event ${mobileShow ? "" : "mobile-toggle"}`} style={{
        //         height: "25px",
        //         borderColor: `${color}${Math.floor(1 * 255).toString(16).padStart(2, 0)}`,
        //         background: `${color}${Math.floor(0.4 * 255).toString(16).padStart(2, 0)}`
        //     }}>
        //         <div className="mbsc-schedule-event-inner md-timeline-template-event-cont-expand">
        //             <span className="md-timeline-template-title">{ev.title}  - "{ev.description}</span>
        //         </div>
        //     </div>
        // }
        else if (data.original.rentmanEvent) {

            return <div data-source="rentman" className={`md-timeline-template-event rentman-event ${mobileShow ? "" : "mobile-toggle"}`} style={{
                borderColor: `${color}${Math.floor(1 * 255).toString(16).padStart(2, 0)}`,
                background: `${color}${Math.floor(0.4 * 255).toString(16).padStart(2, 0)}`
            }}>
                <div className="mbsc-schedule-event-inner md-timeline-template-event-cont-expand rentman-event-inner">
                    <div id="flex-event-expand">
                        <span className="md-timeline-template-title">{ev.description} - {TotalDays} days </span>
                        <span className="md-timeline-template-desc" >{ev.title}</span>

                        <span className="md-timeline-template-time">{
                            data.startDate.toLocaleString('default', { month: 'short', day: 'numeric' }) +
                            " " +
                            data.start +
                            " - " +
                            data.endDate.toLocaleString('default', { month: 'short', day: 'numeric' }) +
                            " " +
                            data.end
                        }
                        </span>
                    </div>
                </div>
            </div>
        }
        else {

            return <div className={`md-timeline-template-event ${mobileShow ? "" : "mobile-toggle"}`} style={{
                borderColor: `${color}${Math.floor(1 * 255).toString(16).padStart(2, 0)}`,
                background: `${color}${Math.floor(0.4 * 255).toString(16).padStart(2, 0)}`
            }}>
                <div className="mbsc-schedule-event-inner md-timeline-template-event-cont-expand">
                    <div id="flex-event-expand">
                        <span className="md-timeline-template-title">{ev.title} - {TotalDays} days</span>
                        <span className="md-timeline-template-desc" >{ev.description}</span>
                    </div>
                    <span className="md-timeline-template-time">{
                        data.startDate.toLocaleString('default', { month: 'short', day: 'numeric' }) +
                        " " +
                        data.start +
                        " - " +
                        data.endDate.toLocaleString('default', { month: 'short', day: 'numeric' }) +
                        " " +
                        data.end
                    }
                    </span>
                </div>
            </div>
        }
    });

    const myScheduleEventContent = React.useCallback((data) => {
        const ev = data.original;
        console.log(ev)
        return <div className="md-timeline-template-event" >
            <span className="md-timeline-template-title">{ev.title}</span>
            <span className="md-timeline-template-time" >{ev.description}</span>
        </div>
    });


    const renderCustomResource = (resource) => {
        if (!resource.styling) {
            return (
                <div className="resource-template-content">
                    <h5 className="resource-name">{resource.name}</h5>
                    <div className="resource-description">{resource.description}</div>
                </div>
            )
        } else if (resource.styling === 'person') {
            return (
                <div className="resource-template-content">
                    <div className="resource-name">{resource.name}</div>
                    <div className="resource-description">{resource.description}</div>
                </div>
            )
        } else if (resource.styling === 'header') {
            return (
                <div className="resource-header-content">
                    <h6 className="resource-header-name">{resource.name}</h6>
                </div>
            )
        }
    }

    const renderResourceHeader = () => {
        return (
            <div className="md-resource-header-template-title">
                <div className="md-resource-header-template-name">Room</div>
                <div className="md-resource-header-template-seats">Capacity</div>
            </div>
        )
    }


    const invalidRecources = React.useMemo(() => {
        return [{
            recurring: {
                repeat: 'daily'
            },
            resource: ['100', '101', '1001'] // disable the parent resources with id 'gro2' and 'gro3'
        }]
    }, []);


    const calendarMarkedDates = React.useMemo(() => {
        return [
            new Date(2020, 2, 15),
            new Date(2020, 2, 22),
            {
                start: new Date(2020, 2, 23),
                end: new Date(2020, 2, 24),
                color: 'red'
            },
            {
                color: 'green',
                recurring: { repeat: 'yearly', month: 12, day: 24 }
            }
        ]
    }, []);



    function flattenArr(arr, flat = []) {
        for (let i = 0; i < arr.length; i++) {
            flat.push(arr[i]);
            if (arr[i].children && arr[i].children.length) {
                flat.pop() // remove headers from list
                flattenArr(arr[i].children, flat);
            }
        }
        return flat;
    }



    function flattenArrAll(arr, flat = []) {
        for (let i = 0; i < arr.length; i++) {
            flat.push(arr[i]);
            if (arr[i].children && arr[i].children.length) {
                flattenArrAll(arr[i].children, flat);
            }
        }
        return flat;
    }

    const handleEventBusy = React.useCallback((ev => {
        setIsBusy(ev.target.checked)
    }));

    const handleEventX = React.useCallback((ev => {
        setIsX(ev.target.checked)
    }));
    const handleEventInvoiced = React.useCallback((ev) => {
        console.log(ev)
        setIsEditable(ev.target.checked);
    }, []);


    const showMobileMenu = (e) => {
        setMobileShow(!mobileShow)
        let el1 = document.querySelectorAll(".motion-air-calendar .mbsc-timeline-resource-empty");
        let el2 = document.querySelectorAll(".motion-air-calendar .mbsc-timeline-resource-col");
        let el3 = document.querySelectorAll(".motion-air-calendar .mbsc-timeline-resources");
        let el4 = document.querySelectorAll(".mbsc-timeline-resource.mbsc-ios.mbsc-ltr");


        if (mobileShow === true) {

            el1.forEach(el => el.classList.add('mobile-menu-open'))
            el2.forEach(el => el.classList.add('mobile-menu-open'))
            el3.forEach(el => el.classList.add('mobile-menu-open'))
            el4.forEach(el => el.classList.add('mobile-menu-open-padding'))


        } else {
            el1.forEach(el => el.classList.remove('mobile-menu-open'))
            el2.forEach(el => el.classList.remove('mobile-menu-open'))
            el3.forEach(el => el.classList.remove('mobile-menu-open'))
            el4.forEach(el => el.classList.remove('mobile-menu-open-padding'))
        }
    }



    // STANDARD CODE:
    const saveEvent = React.useCallback(() => {
        const updatedAtTimestamp = serverTimestamp();

        // Define the new event
        const newEvent = {
            id: uuidv4(),
            title: popupEventTitle,
            description: popupEventDescription,
            start: popupEventDate[0].toString(),
            end: popupEventDate[1].toString(),
            allDay: popupEventAllDay,
            status: popupEventStatus,
            color: selectedColor,
            resource: isChecked, // Updated resources
            editable: isEditable,
            busy: isBusy,
            updatedAt: updatedAtTimestamp,
        };

        if (isEdit) {
            // Editing an existing event
            const index = myEvents.findIndex((x) => x.id === tempEvent.id);
            //   const oldEvent = myEvents[index];
            const originalResources = originalResourcesRef.current; // Capture original resources
            const updatedResources = isChecked; // Current state of resources

            console.log('Old event:', originalResourcesRef.current);
            console.log('temp Event:', tempEvent);

            const newEventList = [...myEvents];
            newEventList.splice(index, 1, newEvent); // Replace old event with new event

            // Update event in the database
            updateEvent(
                eventDatabase,
                tempEvent.id,
                popupEventTitle,
                popupEventDescription,
                popupEventDate[0],
                popupEventDate[1],
                popupEventAllDay,
                popupEventStatus,
                selectedColor,
                isChecked,
                !isEditable,
                isBusy,
                updatedAtTimestamp
            ).then(() => {
                toast({ message: 'Successfully edited event' });
                console.log('Successfully edited event with ID:', tempEvent.id);


                // Fetch and update myEvents from the database
                fetchEvents(eventDatabase).then((events) => {
                    setMyEvents(events);
                });

                console.log('Updated event:', newEventList[index]);
            });

        } else {
            // Creating a new event
            newEvent.color = busyColor;
            setMyEvents([...myEvents, newEvent]);

            const createdAtTimestamp = serverTimestamp();
            const eventId = uuidv4();
            const encodedId = uuidToBase32Hex(eventId);

            console.table({ "input": newEvent.id, "encoded": encodedId, "decoded": base32HexToUUID(encodedId) });

            const gCalEvent = {
                summary: newEvent.title,
                start: {
                    dateTime: popupEventDate[0].toISOString(),
                    timeZone: 'Europe/Oslo',
                },
                end: {
                    dateTime: popupEventDate[1].toISOString(),
                    timeZone: 'Europe/Oslo',
                },
                attendees: [],
                reminders: {
                    useDefault: false,
                    overrides: [
                        { method: 'email', minutes: 24 * 60 },
                        { method: 'popup', minutes: 10 },
                    ],
                },
                extendedProperties: {
                    private: { customId: eventId }, // Your custom ID for tracking
                },
            };
            console.log(`event.extendedProperties.private.customId: customId=${eventId}`);
            //   AddGcalEvent({
            //     event: gCalEvent,
            //     accessToken: gCalAccessToken,
            //     resources: isChecked,
            //     calendar: userSelectedCalendar,
            //     user: user.email
            //   });

            // Adding to Firebase
            addEvents(
                eventDatabase,
                eventId,
                popupEventTitle,
                popupEventDescription,
                popupEventDate[0],
                popupEventDate[1],
                popupEventAllDay,
                popupEventStatus,
                selectedColor,
                isChecked,
                !isEditable,
                isBusy,
                createdAtTimestamp
            );
        }

        setSelectedDate(popupEventDate[0]); // Set the selected date to the start date of the event

        // Refresh events after update or create
        fetchEvents(eventDatabase).then((events) => {
            setMyEvents(events); // Ensure `myEvents` is updated
        });

        setOpen(false); // Close the popup
    }, [
        eventDatabase,
        isEditable,
        isEdit,
        myEvents,
        popupEventAllDay,
        popupEventDate,
        popupEventDescription,
        popupEventStatus,
        popupEventTitle,
        tempEvent,
        selectedColor,
        isChecked,
        isBusy
    ]);

    const deleteLocalEvent = React.useCallback((event) => {
        console.log(event)

        deleteEvent(eventDatabase, event).then(() => {
            fetchEvents(eventDatabase).then((events) => {
                setMyEvents(events)
                console.log('event deleted')
            })
        })
        setTimeout(() => {
            snackbar({
                button: {
                    action: () => {
                        restoreEvent(eventDatabase, event).then(() => {
                            toast({
                                message: 'Event successfully restored'
                            });
                            fetchEvents(eventDatabase).then((events) => {
                                setMyEvents(events)
                            })
                        })
                    },
                    text: 'Undo'
                },
                message: 'Event deleted'
            });
            fetchEvents(eventDatabase).then((events) => {
                setMyEvents(events)
            })

        });


        toast({
            message: 'Event successfully deleted!'
        });
    }, []);


    const isTimeMidnight = (dateString) => {
        const date = new Date(dateString);
        return date.getHours() === 0 && date.getMinutes() === 0 && date.getSeconds() === 0;
    };

    const setTimeToNow = (dateString) => {
        const date = new Date(dateString); // Convert the incoming date string to a Date object
        const now = new Date(); // Get the current date and time

        // Set the hours, minutes, and seconds to the current time
        date.setHours(now.getHours());
        date.setMinutes(now.getMinutes());
        date.setSeconds(now.getSeconds());

        return date; // Return the updated date object
    };

    const loadPopupForm = React.useCallback((event) => {
        setTitle(event.title);
        setDescription(event.description ? event.description : " ");
        if (isTimeMidnight(event.start) && isTimeMidnight(event.end)) {
            console.log("Both start and end times are at midnight (00:00:00).");
            const updatedStart = setTimeToNow(event.start);
            const updatedEnd = setTimeToNow(event.end);
            setDate([updatedStart, updatedEnd]);
        } else {
            console.log("One or both times are not at midnight.");
            setDate([event.start, event.end]);
        }

        setAllDay(event.allDay ?? true);
        setStatus(event.status ?? 'busy');
        setCreated(event.createdAt || null);
        setEdited(event.updatedAt || null);
    }, []);

    // handle popup form changes

    const titleChange = React.useCallback((ev) => {
        setTitle(ev.target.value);
    }, []);

    const descriptionChange = React.useCallback((ev) => {
        setDescription(ev.target.value);
    }, []);

    const allDayChange = React.useCallback((ev) => {
        setAllDay(ev.target.checked);
    }, []);

    const changeStartDate = React.useCallback((start, end) => {
        console.log([start.toDate(), end.toDate()]) //THIS IS A SINGLE DATE

        setDate([start.toDate(), end.toDate()]);
    }, []);

    const changeEndDate = React.useCallback((start, end) => {
        console.log([start.toDate(), end.toDate()]) //THIS IS A SINGLE DATE

        setDate([start.toDate(), end.toDate()]);
    }, []);

    const statusChange = React.useCallback((ev) => {
        setStatus(ev.target.value);
    }, []);

    const onDeleteClick = React.useCallback(() => {
        deleteLocalEvent(tempEvent);
        setOpen(false);
    }, [deleteLocalEvent, tempEvent]);



    // scheduler options
    const onSelectedDateChange = React.useCallback((event) => {
        setSelectedDate(event.date);
    }, []);

    const onEventClick = React.useCallback((args) => {

        // Dont open popup for rentman items:
        originalResourcesRef.current = [...args.event.resource];
        if (args.resource !== 11) {
            setEdit(true);
            setTempEvent({ ...args.event });
            setIsEditable(!args.event.editable)
            setSelectedColor(args.event.color || flattenArr(resources).find(r => r.id === args.event.resource).color);
            setIsBusy(args.event.busy)
            // fill popup form with event data
            loadPopupForm(args.event);
            setAnchor(args.domEvent.target);
            setOpen(true);

            // Custom code:
            // Deals with witch resources are selected for this event:
            setIsChecked(args.event.resource)
        }
    }, [loadPopupForm]);

    const onEventCreated = React.useCallback((args) => {
        setIsEditable(false)
        setEdit(false);
        setTempEvent(args.event)
        originalResourcesRef.current = Array.isArray(args.event.resource)
            ? [...args.event.resource]
            : [args.event.resource];
        setSelectedColor(colors[Math.floor(Math.random() * colors.length)]);
        // Set allDay to true by default
        args.event.allDay = popupEventAllDay;

        // fill popup form with event data
        loadPopupForm(args.event);
        setAnchor(args.target);

        //Custom code:
        setIsChecked([args.event.resource])

        // open the popup
        setOpen(true);
    }, [loadPopupForm, popupEventAllDay]);

    const onEventDeleted = React.useCallback((args) => {
        deleteLocalEvent(args.event)
    }, [deleteLocalEvent]);

    const onEventUpdated = React.useCallback((args) => {
        // here you can update the event in your storage as well, after drag & drop or resize
        const updatedAtTimestamp = serverTimestamp()

        updateEvent(
            eventDatabase,
            args.event.id,
            args.event.title,
            args.event.description,
            args.event.start,
            args.event.end,
            args.event.allDay,
            args.event.status,
            args.event.color,
            args.event.resource,
            args.event.editable,
            args.event.busy,
            updatedAtTimestamp

        ).then(() => {
            toast({
                message: 'Successfully edited event'
            });

            fetchEvents(eventDatabase).then((events) => {
                setMyEvents(events)
                console.log('Successfully edited event with ID: ', args.event.id)
            })
        })
    }, []);

    // datepicker options
    const controls = React.useMemo(() => popupEventAllDay ? ['date'] : ['datetime'], [popupEventAllDay]);
    const responsiveOptions = React.useMemo(() => popupEventAllDay ? {
        medium: {
            controls: ['calendar'],
            touchUi: false
        }
    } : {
        medium: {
            controls: ['calendar', 'time'],
            touchUi: false
        }
    }, [popupEventAllDay]);

    // popup options
    const headerText = React.useMemo(() => isEdit ? 'Edit event' : 'New Event', [isEdit]);
    const popupButtons = React.useMemo(() => {
        if (isEdit) {
            return (<>

                <Button
                    sx={{
                        border: '1px solid #3498B7', // Blue border
                        backgroundColor: 'white', // White background
                        color: '#3498B7', // Blue text color
                        '&:hover': {
                            backgroundColor: '#3498B7', // Blue background on hover
                            color: 'white', // White text on hover
                            borderColor: '#2980B9', // Darker blue border on hover
                        },
                    }}
                    onClick={() => setOpen(false)} // Your cancel handler function
                    variant="outlined"
                    color="secondary"
                >
                    Cancel
                </Button>
                <Button
                    sx={{
                        border: '1px solid #3498B7', // Blue border
                        backgroundColor: 'white', // White background
                        color: '#3498B7', // Blue text color
                        '&:hover': {
                            backgroundColor: '#3498B7', // Blue background on hover
                            color: 'white', // White text on hover
                            borderColor: '#2980B9', // Darker blue border on hover
                        },
                    }}
                    onClick={saveEvent} // Your cancel handler function
                    variant="outlined"
                    color="secondary"
                >
                    Save
                </Button>
            </>
            )
            // ['cancel', {
            //     handler: () => {
            //         saveEvent();
            //     },
            //     keyCode: 'enter',
            //     text: 'Save',
            //     cssClass: 'mbsc-popup-button-primary'
            // }];
        } else {
            return <Button
                sx={{
                    border: '1px solid #3498B7', // Blue border
                    backgroundColor: 'white', // White background
                    color: '#3498B7', // Blue text color
                    '&:hover': {
                        backgroundColor: '#3498B7', // Blue background on hover
                        color: 'white', // White text on hover
                        borderColor: '#2980B9', // Darker blue border on hover
                    },
                }}
                onClick={saveEvent} // Your cancel handler function
                variant="outlined"
                color="secondary"
            >
                Add
            </Button>
        }
    }, [isEdit, saveEvent]);

    const onClose = React.useCallback(() => {
        if (!isEdit) {
            // refresh the list, if add popup was canceled, to remove the temporary event
            setMyEvents([...myEvents]);
        }
        setOpen(false);
    }, [isEdit, myEvents]);

    const selectColor = React.useCallback((color) => {
        setTempColor(color)
    }, []);

    const openColorPicker = React.useCallback((ev) => {
        selectColor(selectedColor);
        setColorAnchor(ev.currentTarget);
        setColorPickerOpen(true);
    }, [selectColor, selectedColor]);

    const changeColor = React.useCallback((ev) => {
        const color = ev.currentTarget.getAttribute('data-value');
        selectColor(color);
        setSelectedColor(color)
        // if (!colorPicker.current.s.buttons.length) {
        //     setSelectedColor(color);
        //     setColorPickerOpen(false);
        // }
    }, [selectColor, setSelectedColor]);

    const onPickerClose = React.useCallback(() => {
        setColorPickerOpen(false);
    }, []);



    const onCellClick = React.useCallback((ev) => {
        // console.log('Cell klicked', ev)
    }, []);



    const onEventHoverIn = React.useCallback((args) => {

        if (Array.isArray(args.event.resource) && args.event.resource.includes(11)) {
            return;
        }
        const event = args.event
        let startTime = event.start.toLocaleTimeString()
        let endTime = event.end.toLocaleTimeString()
        setTitle(event.title)
        setAllDay(event.allDay)
        setDescription(event.description)
        setAnchor(args.domEvent.target);
        setSelectedColor(event.color)
        setIsHoverOpen(true)
        setTempEvent(event)
        setTime([startTime.substring(0, startTime.length - 3), endTime.substring(0, endTime.length - 3)])
        setCreated(event.createdAt || null);
        setEdited(event.updatedAt || null);
        if (timerRef.current) {
            clearTimeout(timerRef.current);
        }
        const resourcesInEvent = []
        args.event.resource.forEach(element => flattenArr(resources).filter((obj) => {
            if (obj.id == element) {
                resourcesInEvent.push(obj)
            }

        }))
        resourcesInEvent.sort(function (a, b) {
            return (a.id - b.id) || a.name.localeCompare(b.name);
        });
        setEventContent([...resourcesInEvent])

    }, []);

    const onEventHoverOut = React.useCallback(() => {
        timerRef.current = setTimeout(() => { setIsHoverOpen(false); }, 150);
    }, []);

    const onMouseEnter = React.useCallback(() => {
        if (timerRef.current) {
            clearTimeout(timerRef.current);
        }
    }, []);

    const onMouseLeave = React.useCallback(() => {
        timerRef.current = setTimeout(() => { setIsHoverOpen(false); }, 150);
    }, []);



    const cursorPosCalcX = (presence) => {
        let position = presence.cursor.x - scrollPos.x

        // IF ELSE ADJUSTS POSITION IF MOBILE MENU IS HIDDEN
        if (mobileShow === false) {
            position = presence.cursor.x - scrollPos.x - 190;
        } else {
            position = presence.cursor.x - scrollPos.x;
        }
        return position
    }

    const cursorPosCalcY = (presence) => {
        const position = presence.cursor.y - scrollPos.y
        return position
    }

    const updatePresence = () => {
        setTimeout(() => {
            updateMyPresence({ cursor: null })
        }, 900000)
    }



    const changeZoomLevel = (text, inst) => {
        const zLevels = [360, 480, 720, 1440]
        let currentZoom = zoomLevel;
        if (text === "minus") {
            if (currentZoom === 1440) {
                console.log('Zoom at maximum')
            } else {
                currentZoom = zLevels.findIndex((element, index) => {
                    if (element === currentZoom) {
                        setZoomLevel(zLevels[index + 1])

                        setTimeout(() => {
                            setSelectedDate(new Date())
                        }, 10)
                    }
                })
            }
        } else {
            if (currentZoom === 360) {
                console.log('Zoom at minimum')
            } else {
                currentZoom = zLevels.findIndex((element, index) => {
                    if (element === currentZoom) {
                        setZoomLevel(zLevels[index - 1]);

                        setTimeout(() => {
                            setSelectedDate(new Date());
                        }, 10)
                    }
                })
            }
        }
    }


    const scrollToUser = (clickedUser) => {
        console.log(clickedUser)
        if (clickedUser === user.email) {
            return;
        }
        const container = document.querySelector('.mbsc-timeline-grid-scroll');
        container.scrollLeft = findNestedObj(others, 'name', clickedUser).cursor.x - 500;
    }






    useEffect(() => {
        setNorwegianHolidays(holidays(formatDate('YYYY', mySelectedDate)));
    }, [mySelectedDate])



    // NOE FEIL MED SET HOLIDAYS HER. TROR DEN BLIR "CALLED" FOR MANGE GANGER INNIMELLOM...
    const onPageLoaded = (day) => {
        // setTimeout(() => {
        //     setCurrentCalendarYear(day.firstDay)
        //     console.log('Setting calendar year to state: ', currentCalendarYear)
        // })
    }


    const onHolidayMouseEnter = React.useCallback(() => {
        if (timerRef.current) {
            clearTimeout(timerRef.current);
        }
    }, []);

    const onHolidayMouseLeave = React.useCallback(() => {
        setIsHoverDayOpen(false)
        timerRef.current = setTimeout(() => { setIsHoverDayOpen(false); }, 10);
    }, []);


    const myDayContent = (day) => {

        const showHolidayInfo = (e, day) => {
            setHolidayAnchor(e.target)
            // FIKSER EN TULLETE BUG
            if (day.info.name === "Kristi Himmelsprettsdag") {
                setHolidayName("Kristi Himmelfartsdag")
            } else {
                setHolidayName(day.info.name)
            }
            setIsHoverDayOpen(true);

            if (timerRef.current) {
                clearTimeout(timerRef.current);
            }
        }

        const date = day.date;
        let formattedDay = formatDate('DD.MM.YY', date)

        const holidayDates = []
        norwegianHolidays.forEach((holi) => {
            holidayDates.push({
                date: formatDate('DD.MM.YY', new Date(holi.date)),
                info: holi
            })
        })


        // HOLIDAYS
        if (holidayDates.map((date) => date.date).includes(formattedDay)) {
            var index = holidayDates.findIndex(v => v.date.includes(formattedDay));
            return <div
                className={`header-date-container calendar-holiday ${formatDate('YYYY-MM-DD', date)}`}
                onMouseOver={(e) => { showHolidayInfo(e, holidayDates[index]) }}
                onMouseLeave={onHolidayMouseLeave}
            >
                <div className='header-date-day'>{formatDate('DDD', date)}</div>
                <p className='header-date-date'>{formatDate('DD.MM.YY', date)}</p>
            </div>;

        }
        // SATURDAY
        else if (day.date.getDay() === 6) {
            return <div className={`header-date-container calendar-saturday ${formatDate('YYYY-MM-DD', date)}`}>
                <div className='header-date-day'>{formatDate('DDD', date)}</div>
                <p className='header-date-date'>{formatDate('DD.MM.YY', date)}</p>
            </div>;

        }
        // SUNDAY
        else if (day.date.getDay() === 0) {
            return <div className={`header-date-container calendar-sunday ${formatDate('YYYY-MM-DD', date)}`}>
                <div className='header-date-day'>{formatDate('DDD', date)}</div>
                <p className='header-date-date'>{formatDate('DD.MM.YY', date)}</p>
            </div>;
        }
        // TODAY
        else if (formatDate('YYYY-MM-DD', day.date) === formatDate('YYYY-MM-DD', new Date())) {
            return <div style={{ backgroundColor: "rgb(227, 255, 227)" }} className={`header-date-container calendar-sunday ${formatDate('YYYY-MM-DD', date)}`}>
                <div className='header-date-day'>{formatDate('DDD', date)}</div>
                <p className='header-date-date'>{formatDate('DD.MM.YY', date)}</p>
            </div>;
        }


        return <div className={`header-date-container ${formatDate('YYYY-MM-DD', date)}`}>
            <div className='header-date-day'>{formatDate('DDD', date)}</div>
            <p className='header-date-date'>{formatDate('DD.MM.YY', date)}</p>
        </div>;
    }


    const myHeader = () => {
        return (
            <React.Fragment>
                <CalendarNav />
                <CalendarPrev />
                <CalendarToday />
                <CalendarNext />
                <Button
                    id={"btnHideResource"}
                    onClick={showMobileMenu}
                    sx={{ fontSize: "10px" }}
                >
                    {mobileShow ? "Hide resource" : "Show resource"}
                </Button>
                {/* 
                BUTTONS FOR ZOOM FUNCTIONALITY. NOT WORKING
                <Button onClick={() => changeZoomLevel("plus")}> + </Button>
                <Button onClick={() => changeZoomLevel("minus")}> - </Button> */}
                <div className="cursor-users-online flex pl-3">
                    {others
                        // Filter out duplicate users by email/name
                        .filter((user, index, self) =>
                            index === self.findIndex((t) => (
                                t.presence.name === user.presence.name
                            ))
                        )
                        .slice(0, 3)
                        .map(({ connectionId, presence }) => {
                            if (!presence?.name || !presence?.color) {
                                return null;
                            }

                            return (
                                <Avatar
                                    key={connectionId}
                                    color={presence.color}
                                    name={presence.name}
                                    displayName={presence.displayName || presence.name}
                                    lastActive={presence.lastActive}
                                    scrollToUser={scrollToUser}
                                    profilePicture={presence.iconImage}
                                />
                            );
                        })}
                    {currentUser?.presence && (
                        <Avatar
                            color={currentUser.presence.color}
                            name={currentUser.presence.name}
                            displayName={'You'}
                            scrollToUser={scrollToUser}
                            profilePicture={userSettings.showProfilePictureInAvatar ? userSettings.userImage : ""}
                        />
                    )}
                </div>
            </React.Fragment>
        );
    }

    return <div>
        {(isLoading || authLoading) ? ( // Check both loading states
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    height: '100vh',
                    backgroundColor: '#f5f5f5'
                }}
            >
                <CircularProgress
                    size={60}
                    thickness={4}
                    sx={{ color: '#3498B7' }} // Using your theme color
                />
            </Box>
        ) : (
            <div
                className='motion-air-calendar-wrapper'
                onPointerMove={mouseMove}
                onPointerLeave={updatePresence}
            >
                {user ? <div>
                    {others.map(({ connectionId, presence }) =>
                        presence.cursor ? (
                            <React.Fragment key={connectionId}>
                                <Cursor
                                    x={cursorPosCalcX(presence)}
                                    y={cursorPosCalcY(presence)}
                                    name={presence.name}
                                    color={presence.color}
                                    id={connectionId}
                                    profilePicture={presence.cursorImage ? presence.iconImage : ""}
                                />
                                <CursorIndicator
                                    x={cursorPosCalcX(presence)}
                                    y={cursorPosCalcY(presence)}
                                    name={presence.name}
                                    color={presence.color}
                                    id={connectionId}
                                />
                            </React.Fragment>
                        ) : null
                    )}
                    {/* <CalendarIntegration></CalendarIntegration> */}
                    <Eventcalendar
                        ref={timelineRef}
                        className='motion-air-calendar'
                        view={viewSettings}
                        data={myEvents}
                        resources={resources}
                        clickToCreate="double"
                        dragToCreate={true}
                        dragToMove={true}
                        dragToResize={true}
                        selectedDate={mySelectedDate}
                        onSelectedDateChange={onSelectedDateChange}
                        onPageLoaded={onPageLoaded}
                        // selectedDate={selectedDate}
                        onEventClick={onEventClick}
                        onEventCreated={onEventCreated}
                        onEventDeleted={onEventDeleted}
                        onEventUpdated={onEventUpdated}
                        colors={calendarColors}
                        renderScheduleEvent={myScheduleEvent}
                        renderScheduleEventContent={myScheduleEventContent}
                        renderHeader={myHeader}
                        invalid={invalidRecources}
                        onEventHoverIn={onEventHoverIn}
                        onEventHoverOut={onEventHoverOut}
                        renderDay={myDayContent}
                    // onResourceExpand={loadChildResources}
                    // renderResource={renderCustomResource}
                    // renderResourceHeader={renderResourceHeader}
                    >
                    </Eventcalendar>

                    <CustomEventPopup
                        isOpen={isOpen}
                        setOpen={setOpen}
                        isEdit={isEdit}
                        saveEvent={saveEvent} // Pass saveEvent here
                        onClose={onClose}
                        fullScreen={true}
                        headerText={headerText}
                        anchor={anchor}
                        items={flattenArr(resources)}
                        onDelete={onDeleteClick}
                        onEventBusyChange={handleEventBusy}
                        onHandleEventXChange={handleEventX}
                        onHandleEventInvoicedChange={handleEventInvoiced}
                        popupEventTitle={popupEventTitle}
                        titleChange={titleChange}
                        popupEventDescription={popupEventDescription}
                        descriptionChange={descriptionChange}
                        selectedColor={selectedColor}
                        openColorPicker={openColorPicker}
                        isBusy={isBusy}
                        isX={isX}
                        isEditable={isEditable}
                        popupEventAllDay={popupEventAllDay}
                        allDayChange={allDayChange}
                        popupEventDate={popupEventDate}
                        changeStartDate={changeStartDate}
                        changeEndDate={changeEndDate}
                        startRef={startRef}
                        endRef={endRef}
                        isChecked={isChecked}
                        handleResourceCheckInEvent={handleResourceCheckInEvent}
                        popupButtons={popupButtons}
                        responsivePopup={responsivePopup}
                        closeOnOverlayClick={true}
                        changeColor={changeColor}
                    />

                    {/* HOVER POPUP */}
                    <Popup
                        display="anchored"
                        anchor={anchor}
                        touchUi={false}
                        showOverlay={false}
                        contentPadding={false}
                        closeOnOverlayClick={false}
                        width={300}
                        // cssClass="md-tooltip"
                        onMouseEnter={onMouseEnter}
                        onMouseLeave={onMouseLeave}
                        isOpen={isHoverOpen} >
                        <div className="md-tooltip-container" onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}>
                            <div className="md-tooltip-header" style={{ backgroundColor: `${selectedColor}${Math.floor(0.4 * 255).toString(16).padStart(2, 0)}` }}>
                                <h1>{popupEventTitle}</h1>
                                <p className='popup-desc'>{popupEventDescription}</p>
                                {!popupEventAllDay ? <p className='popup-time'>{popupEventTime[0]} - {popupEventTime[1]}</p> : <></>}
                                <div className='popup-desc'>
                                    {popupEventCreated !== null ? <p className='popup-desc'>Created at: {formatDate('DD.MM.YY', new Date(popupEventCreated?.seconds * 1000)) + " : " + new Date(popupEventCreated?.seconds * 1000).toLocaleTimeString([], {
                                        hourCycle: 'h23',
                                        hour: '2-digit',
                                        minute: '2-digit'
                                    })}</p> : <></>}
                                    {popupEventEdited !== null ? <p className='popup-desc'>Edited at: {formatDate('DD.MM.YY', new Date(popupEventEdited?.seconds * 1000)) + " : " + new Date(popupEventEdited?.seconds * 1000).toLocaleTimeString([], {
                                        hourCycle: 'h23',
                                        hour: '2-digit',
                                        minute: '2-digit'
                                    })}</p> : <></>}
                                </div>
                            </div>
                            <div className="md-tooltip-content-container">
                                <div className="md-tooltip-content">
                                    <h2 className='selected-event-container'>Bemanning:</h2>
                                    {eventContent.map(item => {
                                        if (item.id <= 11) {
                                            return <div className='selected-event-crew' key={item.id}>{item.name}</div>
                                        }
                                    })}
                                    <h2 className='selected-event-container'>{Math.max(...eventContent.map(item => item.id)) > 10 ? 'Equipment' : ''}</h2>
                                    {eventContent.map(item => {
                                        if (item.id > 11) {
                                            return <div className='selected-event-equipment' key={item.id}>{item.name}</div>
                                        } else if (!item) {
                                            return <div></div>
                                        }
                                    })}
                                </div>
                            </div>
                        </div>
                    </Popup>
                    {/* Popup for hovering holiday dates in header */}
                    <Popup
                        display="anchored"
                        anchor={holidayAnchor}
                        touchUi={false}
                        showOverlay={false}
                        contentPadding={false}
                        closeOnOverlayClick={false}
                        width={"auto"}
                        onMouseEnter={onHolidayMouseEnter}
                        onMouseLeave={onHolidayMouseLeave}
                        isOpen={isHoverDayOpen} >
                        <div className="md-tooltip-container" onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}>
                            <div >
                                <p style={{ textAlign: "center" }} className='popup-desc'>{holidayName}</p>
                            </div>
                        </div>
                    </Popup>
                </div> : <></>}
            </div>
        )}
    </div>
}

export default Calendar;
