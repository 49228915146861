import React, { useState, useEffect } from 'react';
import { Popover, Stack, Grid, Box, IconButton, Tooltip, Typography } from '@mui/material';

const colors = [
  '#FFEB3C',
  '#FFD700',
  '#FF9900',
  '#FF8C00',
  '#FF99FF',
  '#FF66FF',
  '#9C26B0',
  '#8E24AA',
  '#3F51B5',
  '#3949AB',
  '#03A9F4',
  '#009788',
  '#66FFCC',
  '#4CAF50',
  '#4BAF4F',
  '#7E5D4E',
  '#795548',
];

function ColorPicker({ selectedColor, changeColor }) {
  const [anchorEl, setAnchorEl] = useState(null);

  // Ensure the popup opens when the button is clicked
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'color-picker-popover' : undefined;

  // useEffect(() => {
  //   console.log('ColorPicker: selectedColor updated:', selectedColor);
  // }, [selectedColor]); // Ensure the popup updates when color changes

  return (
    <>
      <Tooltip title="Choose Color">
        <IconButton
          sx={{
            borderRadius: '1px',
            '&:hover': {
              backgroundColor: 'rgba(0, 0, 0, 0.04)',
            },
          }}
          aria-describedby={id}
          variant="contained"
          onClick={handleClick}
        >
          <Stack direction="row" alignItems="center" spacing={1}>
            <Box
              sx={{
                width: '24px',
                height: '24px',
                backgroundColor: selectedColor, // This should reflect the selected color
                border: '1px solid rgb(204, 204, 204)',
              }}
            />
            <Typography
              variant="body2"
              component="span"
              sx={{
                fontSize: '0.875rem', // Matches default MUI FormControlLabel font size
                lineHeight: '1.4375em', // Matches checkbox label line height
                verticalAlign: 'middle', // Ensures alignment with checkboxes
                color: 'black', // Matches checkbox label color
              }}
            >
              Color
            </Typography>
          </Stack>
        </IconButton>
      </Tooltip>

      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        <Box sx={{ p: 2 }}>
          <Grid container spacing={1}>
            {colors.map((c) => (
              <Grid item key={c}>
                <IconButton
                  sx={{
                    width: '32px',
                    height: '32px',
                    backgroundColor: c,
                    '&:hover': {
                      border: '2px solid #aaa',
                    },
                  }}
                  data-value={c}
                  onClick={(ev) => {
                    changeColor(ev);  // Pass event to changeColor handler
                    handleClose();  // Close the popup after selecting a color
                  }} />
              </Grid>
            ))}
          </Grid>
        </Box>
      </Popover>
    </>
  );
}

export default ColorPicker;