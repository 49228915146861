const array = [
  {
    id: 1,
    name: "Pål A. Odden",
    color: "#ffeb3c",
    styling: "person",
    email: "paal@motionair.no",
  },
  {
    id: 2,
    name: "Reiel Lien Bruland",
    color: "#f44437",
    styling: "person",
    email: "reiel@motionair.no",
  },
  {
    id: 3,
    name: "Stian Pedersen",
    color: "#3f51b5",
    styling: "person",
    email: "stian@motionair.no",
  },
  {
    id: 4,
    name: "Marius Seierstad",
    color: "#4baf4f",
    styling: "person",
    email: "post@mariusseierstad.no",
  },
  {
    id: 5,
    name: "Nikolas Gogstad-Andersen",
    color: "#ff9900",
    styling: "person",
    email: "gogstad@gmail.com",
  },
  {
    id: 6,
    name: "Ultra Arm",
    color: "#f7961e",
    background: "#76e083",
  },
  {
    id: 7,
    name: "Shotover G1",
    color: "#34c8e0",
    background: "#76e083",
  },
  {
    id: 8,
    name: "Alta X",
    color: "#f7961e",
    background: "#76e083",
  },
  {
    id: 9,
    name: "Angenieux EZ",
    color: "#34c8e0",
    background: "#76e083",
  },
  {
    id: 10,
    name: "Inspire 2 AA (X7)",
    color: "#f7961e",
    background: "#76e083",
  },
  {
    id: 11,
    name: "Inspire 2 AF (X7)",
    color: "#34c8e0",
    background: "#76e083",
  },
  {
    id: 12,
    name: "Alexa 35",
    color: "#f7961e",
    background: "#76e083",
  },
  {
    id: 13,
    name: "Sony FX6",
    color: "#34c8e0",
    background: "#76e083",
  },
  {
    id: 14,
    name: "Movi Pro",
    color: "#f7961e",
    background: "#76e083",
  },
  {
    id: 15,
    name: "Wirecam",
    color: "#34c8e0",
    background: "#76e083",
  },
  {
    id: 16,
    name: "Shotover F1",
    color: "#34c8e0",
    background: "#76e083",
  },
  {
    id: 17,
    name: "Helikopter",
    color: "#34c8e0",
    background: "#76e083",
  },
  {
    id: 18,
    name: "MB Vito",
    color: "#34c8e0",
    background: "#76e083",
  },
  {
    id: 19,
    name: "MB X-Klasse",
    color: "#34c8e0",
    background: "#76e083",
  },
];

//     yellow: { default: "#FFBB00", dim: '#503F10' },
//     red: { default: '#FF3366', dim: '#501D2A' }, MARIUS
//     blue: { default: "#0088FF", dim: "#103250" },PÅL ODDEN
//     green: { default: "#22DD88", dim: "#194832" },REIEL BRULAND
//     orange: { default: "#FF8800", dim: "#503210" }, STIAN
//     pink: { default: "#FF0099", dim: "#501037" },
//     purple: { default: "#AA44FF", dim: "#3B2150" }, NIKOLAS

const resources = [
  {
    id: 100,
    name: "Crew",
    color: "#ff9900",
    styling: "header",
    eventCreation: false,
    eventSelectable: false,
    children: [
      {
        id: 1,
        name: "Pål A. Odden",
        type: "person",
        eventSelectable: true,
        color: "#ffeb3c",
        iconColor: { default: "#0088FF", dim: "#103250" },
        styling: "person",
        email: "paal@motionair.no",
      },
      {
        id: 2,
        name: "Reiel Lien Bruland",
        type: "person",
        eventSelectable: true,
        color: "#99d8ff",
        iconColor: { default: "#22DD88", dim: "#194832" },
        styling: "person",
        email: "reiel@motionair.no",
      },
      {
        id: 3,
        name: "Stian Pedersen",
        type: "person",
        eventSelectable: true,
        color: "#3f51b5",
        iconColor: { default: "#FF8800", dim: "#503210" },
        styling: "person",
        email: "stian@motionair.no",
      },
      {
        id: 4,
        name: "BRENT",
        eventSelectable: true,
        type: "person",
        color: "#d4d4d4",
        iconColor: { default: "#FF3366", dim: "#501D2A" },
        styling: "person",
        email: "booking@brent.as",
      },
      {
        id: 1002,
        name: "Rentman",
        eventSelectable: false,
        color: "#ff9900",
        styling: "header",
        collapsed: true,
        eventCreation: false,
        children: [
          {
            id: 11,
            name: "Rentman",
            eventSelectable: false,
            color: "#d4d4d4",
            iconColor: { default: "#FF3366", dim: "#501D2A" },
            styling: "person",
            eventDragBetweenResources: false,
            eventDragBetweenSlots: false,
            dragBetweenResources: false,
            eventDragInTime: false,
            // eventCreation: false,
            email: "booking@brent.as",
          },
        ],
      },
      {
        id: 1001,
        name: "Freelancers",
        eventSelectable: false,
        color: "#ff9900",
        styling: "header",
        collapsed: true,
        eventCreation: false,
        children: [
          {
            id: 5,
            name: "Marius Seierstad",
            type: "person",
            eventSelectable: true,
            color: "#4baf4f",
            iconColor: { default: "#FF3366", dim: "#501D2A" },
            styling: "person",
            email: "post@mariusseierstad.no",
          },
          {
            id: 6,
            name: "Nikolas Gogstad-Andersen",
            type: "person",
            eventSelectable: true,
            color: "#ff9900",
            iconColor: { default: "#AA44FF", dim: "#3B2150" },
            styling: "person",
            email: "gogstad@gmail.com",
          },
          {
            id: 7,
            name: "Otto Tangstad",
            type: "person",
            eventSelectable: true,
            color: "#ff9900",
            styling: "person",
          },
          {
            id: 8,
            name: "Freelancer 1",
            type: "person",
            eventSelectable: true,
            color: "#ff9900",
            styling: "person",
            email: "test@test.com",
            iconColor: { default: "#FF0099", dim: "#501037" },
          },
          {
            id: 9,
            name: "Freelancer 2",
            type: "person",
            eventSelectable: true,
            color: "#ff9900",
            styling: "person",
          },
        ],
      },
      {
        id: 10,
        name: "Admin",
        type: "person",
        eventSelectable: true,
        color: "#99ffad",
        styling: "person",
      },
    ],
  },
  {
    id: 101,
    name: "Equipment",
    eventSelectable: false,
    color: "#ff9900",
    styling: "header",
    eventCreation: false,
    children: [
      {
        id: 20,
        name: "Radical",
        type: "equipment",
        eventSelectable: true,
        color: "#ff99d4",
        background: "#76e083",
      },
      {
        id: 21,
        name: "Shotover G1",
        type: "equipment",
        eventSelectable: true,
        color: "#34c8e0",
        background: "#76e083",
      },
      {
        id: 22,
        name: "Alta X",
        type: "equipment",
        eventSelectable: true,
        color: "#f7961e",
        background: "#76e083",
      },
      {
        id: 23,
        name: "Angenieux EZ",
        type: "equipment",
        eventSelectable: true,
        color: "#34c8e0",
        background: "#76e083",
      },
      {
        id: 35,
        name: "Inspire 3 Kit 1",
        type: "equipment",
        eventSelectable: true,
        color: "#f7961e",
        background: "#76e083",
      },
      {
        id: 24,
        name: "Inspire 2 AA (X7)",
        type: "equipment",
        eventSelectable: true,
        color: "#f7961e",
        background: "#76e083",
      },
      {
        id: 25,
        name: "Inspire 2 AF (X7)",
        type: "equipment",
        eventSelectable: true,
        color: "#34c8e0",
        background: "#76e083",
      },
      {
        id: 26,
        name: "Alexa 35",
        type: "equipment",
        eventSelectable: true,
        color: "#f7961e",
        background: "#76e083",
      },
      {
        id: 27,
        name: "Sony FX6",
        type: "equipment",
        eventSelectable: true,
        color: "#34c8e0",
        background: "#76e083",
      },
      {
        id: 28,
        name: "Movi Pro",
        type: "equipment",
        eventSelectable: true,
        color: "#f7961e",
        background: "#76e083",
      },
      {
        id: 36,
        name: "Ronin 2",
        type: "equipment",
        eventSelectable: true,
        color: "#34c8e0",
        background: "#76e083",
      },
      {
        id: 29,
        name: "Wirecam",
        type: "equipment",
        eventSelectable: true,
        color: "#34c8e0",
        background: "#76e083",
      },
      {
        id: 30,
        name: "Black Arm",
        type: "equipment",
        eventSelectable: true,
        color: "#34c8e0",
        background: "#76e083",
      },
      {
        id: 31,
        name: "Innleid Utstyr",
        type: "equipment",
        eventSelectable: true,
        color: "#34c8e0",
        background: "#76e083",
      },
      {
        id: 32,
        name: "MB Vito",
        type: "equipment",
        eventSelectable: true,
        color: "#34c8e0",
        background: "#76e083",
      },
      {
        id: 33,
        name: "Ford Raptor",
        type: "equipment",
        eventSelectable: true,
        color: "#34c8e0",
        background: "#76e083",
      },
      {
        id: 34,
        name: "MB ML",
        type: "equipment",
        eventSelectable: true,
        color: "#f7961e",
        background: "#76e083",
      },
    ],
  },
];

export { array, resources };
