import React from "react";
import  "./Avatar.css";
import { useAuth } from '../Hooks/useAuth';

const IMAGE_SIZE = 48;


const convertHexToRGBA = (hexCode, opacity = 1) => {
  let hex = hexCode.replace('#', '');

  if (hex.length === 3) {
    hex = `${hex[0]}${hex[0]}${hex[1]}${hex[1]}${hex[2]}${hex[2]}`;
  }
  const r = parseInt(hex.substring(0, 2), 16);
  const g = parseInt(hex.substring(2, 4), 16);
  const b = parseInt(hex.substring(4, 6), 16);

  /* Backward compatibility for whole number based opacity values. */
  if (opacity > 1 && opacity <= 100) {
    opacity = opacity / 100;
  }

  return `rgba(${r},${g},${b},${opacity})`;
};


export function Avatar({ name, displayName, color, lastActive, scrollToUser, profilePicture }) {
  // Add checks for required props
  if (!name || !color) {
    // console.warn('Avatar missing required props:', { name, color });
    return null;
  }

  // Safely handle lastActive
  const startTime = lastActive ? new Date(lastActive) : new Date();
  
  function getMinutesBetweenDates(startDate, endDate) {
    if (!startDate || !endDate) return 0;
    var diff = endDate.getTime() - startDate.getTime();
    return (diff / 60000);
  }

  return (
    <div
      className={'avatar-container'}
      data-tooltip={name}
      onClick={() => scrollToUser && scrollToUser(name)}
    >
      <p>{(name || '?').charAt(0).toUpperCase()}</p>
      <div
        style={{
          background: profilePicture ? `` : convertHexToRGBA(color.dim || '#808080', 0.5),
          backgroundImage: profilePicture ? `url(${profilePicture})` : "",
          backgroundSize: "contain",
          userSelect: 'none',
          boxShadow: `inset 0px 0px 0px 2px ${color.color || '#808080'}, 0px 8px 16px rgba(0,0,0,0.4)`,
          BorderColor: color.color || '#808080'
        }}
        alt={name}
        height={IMAGE_SIZE}
        width={IMAGE_SIZE}
        className={'avatar-background'}
      >
        <span className="avatar-text">
          {displayName || name}
          <br />
          <span
            style={{
              width: 'min-content',
              fontSize: '10px',
              zIndex: 9999
            }}
          >
            {lastActive && getMinutesBetweenDates(startTime, new Date()) ?
              'last active: ' + Math.round(getMinutesBetweenDates(startTime, new Date())) + " minutes ago"
              : ""}
          </span>
        </span>
      </div>
    </div>
  );
}