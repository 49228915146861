import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { TextField, Stack } from '@mui/material';
import dayjs from 'dayjs';
import { useState } from 'react';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';

function CustomTimeDatePicker({ popupEventDate, changeStartDate, changeEndDate, popupEventAllDay }) {
  const [openStartTime, setOpenStartTime] = useState(false);
  const [openEndTime, setOpenEndTime] = useState(false);

  const handleStartDateAccept = (value) => {
    changeStartDate(value, dayjs(popupEventDate[1]));
    setOpenStartTime(false);
    setOpenEndTime(true); 
  };

  const handleEndDateAccept = (value) => {
    changeEndDate(dayjs(popupEventDate[0]), value);
    setOpenEndTime(false);
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <Stack spacing={2} sx={{ backgroundColor: 'white', padding: '10px 0' }}>
        
        <DateTimePicker
          label="Start Time"
          value={dayjs(popupEventDate[0])}
          onChange={(value) => changeStartDate(value, dayjs(popupEventDate[1]))}
          open={openStartTime}
          onOpen={() => setOpenStartTime(true)}
          onClose={() => setOpenStartTime(false)}
          ampm={false}
          format={popupEventAllDay ? 'DD.MM.YY' : 'DD.MM.YY HH:mm'}
          views={popupEventAllDay ? ['year', 'month', 'day'] : ['year', 'month', 'day', 'hours', 'minutes']}
          shouldDisableDate={(date) => popupEventDate[1] && dayjs(date).isAfter(dayjs(popupEventDate[1]), 'day')}
          onAccept={handleStartDateAccept}
          localeText={{ dateTimePickerToolbarTitle: 'Select Start date and time' }}
          slotProps={{
            textField: {
              fullWidth: true,
              onClick: () => setOpenStartTime(true),
            },
            dialog: {
              sx: {
                '& .MuiPickersToolbar-content': {
                  display: 'flex',
                  alignItems: 'flex-end',
                },
                '& .MuiDialog-root': {
                  zIndex: 9999,
                },
              },
            },
          }}
        />

        <DateTimePicker
          label="End Time"
          value={dayjs(popupEventDate[1])}
          onChange={(value) => changeEndDate(dayjs(popupEventDate[0]), value)}
          open={openEndTime}
          onOpen={() => setOpenEndTime(true)}
          onClose={() => setOpenEndTime(false)}
          ampm={false}
          format={popupEventAllDay ? 'DD.MM.YY' : 'DD.MM.YY HH:mm'}
          views={popupEventAllDay ? ['year', 'month', 'day'] : ['year', 'month', 'day', 'hours', 'minutes']}
          shouldDisableDate={(date) => dayjs(date).isBefore(dayjs(popupEventDate[0]), 'day')}
          onAccept={handleEndDateAccept}
          localeText={{ dateTimePickerToolbarTitle: 'Select End date and time' }}
          slotProps={{
            textField: {
              fullWidth: true,
              onClick: () => setOpenEndTime(true),
            },
            dialog: {
              sx: {
                '& .MuiPickersToolbar-content': {
                  display: 'flex',
                  alignItems: 'flex-end',
                },
                '& .MuiDialog-root': {
                  zIndex: 9999,
                },
              },
            },
          }}
        />

      </Stack>
    </LocalizationProvider>
  );
}

export default CustomTimeDatePicker;