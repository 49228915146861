import React, { useState, useEffect, useRef } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  FormControlLabel,
  Checkbox,
  Stack,
  DialogActions,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Grid,
  CircularProgress,
  Typography,
  Button,
} from '@mui/material';
import { useTheme, useMediaQuery } from '@mui/material';  // Import useTheme and useMediaQuery
import ColorPicker from './ColorPicker';
import CustomTimeDatePicker from './CustomTimeDatePicker';

function CustomEventPopup({
  isOpen,
  onClose,
  headerText,
  anchor,
  items,
  onDelete,
  onEventBusyChange,
  onHandleEventXChange,
  onHandleEventInvoicedChange,
  popupEventTitle,
  titleChange,
  popupEventDescription,
  descriptionChange,
  selectedColor,
  changeColor,
  openColorPicker,
  isBusy,
  isX,
  isEditable,
  popupEventAllDay,
  allDayChange,
  popupEventDate,
  changeStartDate,
  changeEndDate,
  startRef,
  endRef,
  isChecked,
  handleResourceCheckInEvent, // Pass the function to the component
  popupButtons,
  responsivePopup,
  closeOnOverlayClick,
  saveEvent,
  setOpen,
  isEdit,
}) {
  const [title, setTitle] = useState(popupEventTitle || '');
  const [description, setDescription] = useState(popupEventDescription || '');
  const [available, setAvailable] = useState(isBusy || false);
  const [invoiced, setInvoiced] = useState(isEditable || false);
  const [filterText, setFilterText] = useState('');
  const [crewItems, setCrewItems] = useState([]);
  const [equipmentItems, setEquipmentItems] = useState([]);
  const [loading, setLoading] = useState(true);
  const textFieldRef = useRef(null);

  const theme = useTheme();  // Initialize useTheme hook
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm')); // 'sm' is the breakpoint for small screens

  useEffect(() => {
    if (isOpen) {
      console.log("useEffect handleDialogEntered")
      const timer = setTimeout(() => {
        // Only focus the input after dialog is fully rendered
        if (textFieldRef.current) {
          textFieldRef.current.focus();
        }
      }, 300); // Add a small delay (200ms)

      return () => clearTimeout(timer); // Cleanup timeout on component unmount or isOpen change
    }
  }, [isOpen]); // Trigger effect whenever isOpen changes


  useEffect(() => {
    if (items && Array.isArray(items)) {
      setCrewItems(items.filter((item) => item.type === 'person'));
      setEquipmentItems(items.filter((item) => item.type === 'equipment'));
      setLoading(false);
    } else {
      setLoading(false);
    }
  }, [items]);

  const handleFilterChange = (event) => setFilterText(event.target.value);

  const filteredCrewItems = crewItems.filter((item) =>
    item.name?.toLowerCase().includes(filterText.toLowerCase())
  );

  const filteredEquipmentItems = equipmentItems.filter((item) =>
    item.name?.toLowerCase().includes(filterText.toLowerCase())
  );


  // Function to handle keyDown event

  // Add event listener on mount and remove it on unmount
  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === 'Enter') {
        saveEvent();
      }
    };
    window.addEventListener('keydown', handleKeyDown);

    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [isEdit, saveEvent]);  // Dependencies to re-add the listener when needed



  if (loading) {
    return (
      <Dialog open={isOpen} onClose={onClose} fullWidth maxWidth="sm">
        <DialogTitle>{headerText}</DialogTitle>
        <DialogContent sx={{ pt: 2, display: 'flex', justifyContent: 'center' }}>
          <CircularProgress />
        </DialogContent>
      </Dialog>
    );
  }


  return (
    <Dialog
      disableRestoreFocus
      open={isOpen}
      onClose={onClose}
      fullWidth
      fullScreen={fullScreen}
      maxWidth="sm"
      anchor={anchor}
      disableEscapeKeyDown={!closeOnOverlayClick}
      sx={{
        '& .MuiDialog-paper': {
          width: '100%',  // Full width by default
          '@media (max-width: 600px)': {
            width: '100%', // Full width on small screens
          },
          '@media (min-width: 600px) and (max-width: 900px)': {
            width: '80%', // 80% width on medium screens
          },
          '@media (min-width: 900px)': {
            width: '70%', // 70% width on larger screens
          },
        },
        '& .MuiDialogTitle-root': {
          paddingBottom: '10px', // Remove any bottom padding from the title
          boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.05)", // Soft shadow under the title
          zIndex: 2, // Ensures it stays above content
        },
        '& .MuiDialogContent-root': {
          paddingTop: '35px !important', // Add space below the title
        },
        '& .MuiDialogActions-root': {
          justifyContent: 'space-around',
          boxShadow: "0px -2px 1px rgba(0, 0, 0, 0.05)", // Soft shadow above the buttons
          zIndex: 2, // Ensures it's above content
        }
      }}
    >
      <DialogTitle>{headerText}</DialogTitle>

      <DialogContent sx={{ pt: 2, }}>
        <Stack spacing={2}>
          <TextField
            inputRef={textFieldRef} // Apply ref for focus
            // autoFocus
            label="Title"
            value={popupEventTitle}
            onChange={(e) => {
              setTitle(e.target.value);
              titleChange(e);
            }}
            variant="outlined"
            fullWidth
          />

          <TextField
            label="Description"
            value={popupEventDescription}
            onChange={(e) => {
              setDescription(e.target.value);
              descriptionChange(e);
            }}
            variant="outlined"
            multiline
            fullWidth
          />


          <Stack
            sx={{
              '& .MuiTypography-root': {
                fontSize: '15px',
                display: 'inline', // Ensure it's visually similar to <p> elements
                fontWeight: "300",
              },
            }}
            direction="row" justifyContent="space-between">

            <ColorPicker selectedColor={selectedColor} changeColor={changeColor} />

            <FormControlLabel
              control={
                <Checkbox
                  checked={available}  // Represents "Busy" state
                  onChange={(e) => {
                    if (!invoiced) {  // Only allow changing "busy" if "invoiced" is not checked
                      setAvailable(e.target.checked);  // Update "busy" state
                      onEventBusyChange(e);  // Call parent function for busy change
                    }
                  }}
                  disabled={invoiced}  // Disable "busy" if "invoiced" is checked
                />
              }
              label="Busy"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={popupEventAllDay}  // Represents "All day" state
                  onChange={(e) => {
                    allDayChange(e)

                  }}
                />
              }
              label="All Day"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={invoiced}  // Represents "Invoiced" state
                  onChange={(e) => {
                    setInvoiced(e.target.checked);  // Update "invoiced" state
                    if (e.target.checked) {
                      setAvailable(false);  // Reset "busy" state when "invoiced" is checked
                      onEventBusyChange({ target: { checked: !e.target.checked } }) // invert the actual "busy" state when "invoiced" is checked
                    }
                    onHandleEventInvoicedChange(e);  // Call parent function for invoiced change
                  }}
                />
              }
              label="Invoiced"
            />

          </Stack>

          <CustomTimeDatePicker
            popupEventDate={popupEventDate}
            changeStartDate={changeStartDate}
            changeEndDate={changeEndDate}
            popupEventAllDay={popupEventAllDay}
          />

          <TextField
            label="Filter Items"
            value={filterText}
            onChange={handleFilterChange}
            variant="outlined"
            fullWidth
            sx={{ mb: 2 }}
          />

          <Typography variant="h6">Crew</Typography>
          <List>
            <Grid container spacing={0}>
              {filteredCrewItems.map((item) =>
                item.eventSelectable ? (
                  <Grid item xs={12} sm={6} key={item.id}>
                    <ListItem disablePadding>
                      <ListItemButton dense onClick={() => handleResourceCheckInEvent(item)}>
                        <ListItemIcon>
                          <Checkbox checked={isChecked.includes(item.id)} />
                        </ListItemIcon>
                        <ListItemText primary={item.name} />
                      </ListItemButton>
                    </ListItem>
                  </Grid>
                ) : null
              )}
            </Grid>
          </List>

          <Typography variant="h6">Equipment</Typography>
          <List>
            <Grid container spacing={0}>
              {filteredEquipmentItems.map((item) =>
                item.eventSelectable ? (
                  <Grid item xs={12} sm={6} key={item.id}>
                    <ListItem disablePadding>
                      <ListItemButton dense onClick={() => handleResourceCheckInEvent(item)}>
                        <ListItemIcon>
                          <Checkbox checked={isChecked.includes(item.id)} />
                        </ListItemIcon>
                        <ListItemText primary={item.name} />
                      </ListItemButton>
                    </ListItem>
                  </Grid>
                ) : null
              )}
            </Grid>
          </List>
        </Stack>
      </DialogContent>

      <DialogActions
        sx={{
          justifyContent: 'space-around',
          boxShadow: "rgba(0, 0, 0, 0.2) 0px 3px 5px -1px, rgba(0, 0, 0, 0.14) 0px 6px 10px 0px, rgba(0, 0, 0, 0.12) 0px 1px 18px 0px"
        }}>
        {isEdit ? <>

          <Button
            sx={{
              border: '1px solid rgba(255, 6, 6, 0.62)', // Blue border
              backgroundColor: 'white', // White background
              color: '#3498B7', // Blue text color
              '&:hover': {
                backgroundColor: 'rgba(255, 6, 6, 0.62)', // Blue background on hover
                color: 'white', // White text on hover
                borderColor: 'rgba(255, 6, 6, 0.62)', // Darker blue border on hover
              },
            }}
            onClick={() => onDelete()} // Your cancel handler function
            variant="outlined"
            color="secondary"
          >
            Delete
          </Button>
          <Button
            sx={{
              border: '1px solid #3498B7', // Blue border
              backgroundColor: 'white', // White background
              color: '#3498B7', // Blue text color
              '&:hover': {
                backgroundColor: '#3498B7', // Blue background on hover
                color: 'white', // White text on hover
                borderColor: '#2980B9', // Darker blue border on hover
              },
            }}
            onClick={() => setOpen(false)} // Your cancel handler function
            variant="outlined"
            color="secondary"
          >
            Cancel
          </Button>
          <Button
            sx={{
              border: '1px solid #3498B7', // Blue border
              backgroundColor: 'white', // White background
              color: '#3498B7', // Blue text color
              '&:hover': {
                backgroundColor: '#3498B7', // Blue background on hover
                color: 'white', // White text on hover
                borderColor: '#2980B9', // Darker blue border on hover
              },
            }}
            onClick={saveEvent}
            variant="outlined"
            color="secondary"
          >
            Save
          </Button>
        </> :
          <>
            <Button
              sx={{
                border: '1px solid #3498B7', // Blue border
                backgroundColor: 'white', // White background
                color: '#3498B7', // Blue text color
                '&:hover': {
                  backgroundColor: '#3498B7', // Blue background on hover
                  color: 'white', // White text on hover
                  borderColor: '#2980B9', // Darker blue border on hover
                },
              }}
              onClick={() => setOpen(false)} // Your cancel handler function
              variant="outlined"
              color="secondary"
            >
              Cancel
            </Button>
            <Button
              sx={{
                border: '1px solid #3498B7', // Blue border
                backgroundColor: 'white', // White background
                color: '#3498B7', // Blue text color
                '&:hover': {
                  backgroundColor: '#3498B7', // Blue background on hover
                  color: 'white', // White text on hover
                  borderColor: '#2980B9', // Darker blue border on hover
                },
              }}
              onClick={saveEvent}
              variant="outlined"
              color="secondary"
            >
              Add
            </Button>

          </>
        }

      </DialogActions>
    </Dialog >
  );
}

export default CustomEventPopup;