import { BrowserRouter, Routes, Route, useNavigate } from "react-router-dom";
import { useEffect } from "react";
import Layout from "./Pages/Layout";
import Calendar from "./Pages/Calendar";
import LogIn from "./Pages/LogIn";
import NoPage from "./Pages/NoPage";
import Agenda from "./Pages/Agenda";
import Todo from "./Pages/Todo";
import Settings from "./Pages/Settings";
import "./index.css";

import "./Pages//Layout.css";

import { ProtectedRoute } from "./Components/ProtectedRoutes";
import { AuthProvider } from "./Hooks/AuthProvider";
import { createTheme, ThemeProvider } from "@mui/material/styles";

import { useServiceWorker } from "./Hooks/useServiceWorker";
import { confirm } from "@mobiscroll/react";
import { useAuth } from "./Hooks/useAuth";

const AppRoutes = () => {
  const { user } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    if (user && window.location.pathname === '/') {
      navigate('/calendar');
    }
  }, [user, navigate]);

  return (
    <Routes>
      <Route path="/" element={<Layout hideHeaderPaths={["/"]} />}>
        <Route index element={<LogIn />} />
        <Route
          path="Calendar"
          element={
            <ProtectedRoute>
              <Calendar />
            </ProtectedRoute>
          }
        />
        <Route
          path="Agenda"
          element={
            <ProtectedRoute>
              <Agenda />
            </ProtectedRoute>
          }
        />
        <Route
          path="Todo"
          element={
            <ProtectedRoute>
              <Todo />
            </ProtectedRoute>
          }
        />
        <Route
          path="Settings"
          element={
            <ProtectedRoute>
              <Settings />
            </ProtectedRoute>
          }
        />
      </Route>
    </Routes>
  );
};

const App = () => {
  const { waitingWorker, showReload, reloadPage } = useServiceWorker();

  const theme = createTheme({
    palette: {
      primary: {
        main: "#3498B7",
      },
      secondary: {
        main: "##2E2E2E",
      },
    },
    typography: {
      fontFamily: [
        "Montserrat",
        "sans-serif",
        "-apple-system",
        "BlinkMacSystemFont",
        '"Segoe UI"',
        "Roboto",
        '"Helvetica Neue"',
        "Arial",
        "sans-serif",
        '"Apple Color Emoji"',
        '"Segoe UI Emoji"',
        '"Segoe UI Symbol"',
      ].join(","),
    },
    breakpoints: {
      values: {
        xs: 0,    // Extra small (mobile)
        sm: 600,  // Small (tablet)
        md: 960,  // Medium (laptop)
        lg: 1280, // Large (desktop)
        xl: 1920, // Extra large (large screens)
      },
    },
  });

  useEffect(() => {
    if (showReload && waitingWorker) {
      confirm({
        title: "New version available",
        message: "Press Reload to get latest changes.",
        okText: "Reload",
        cancelText: "Cancel",
        display: "bottom",
        callback: function (result) {
          if (result === true) {
            reloadPage();
          } else return;
        },
      });
    } else {
    }
  }, [waitingWorker, showReload, reloadPage]);

  return (
    <BrowserRouter>
      <AuthProvider>
        <ThemeProvider theme={theme}>
          <AppRoutes />
        </ThemeProvider>
      </AuthProvider>
    </BrowserRouter>
  );
};

export default App;
